import type { ListingConditions, RvSearchRequest, RvSearchSorts, RvTypes } from '~/types/api'
import { ListingConditionsList, RvTypesGuard } from '~/types/api'
import type { IGooglePlace } from '~/types/common/google-place'
import type { IGeolocation } from '~/types/geolocation'
import type { ISearchQuery } from '~/types/query/search'
import type { Drivables, ISearchFilters, Towables } from '~/types/search/filters'
import type { ISearchParameters } from '~/types/search/search'
import { getGooglePlaceByName } from './google-maps/google-place'

const arrayUtils = useArrayUtils()
export const searchParametersToApiRequest = (params: ISearchParameters): RvSearchRequest => {
  const classes = []
  const listingConditions = []

  for (const key in params.filters?.rvType) {
    if (params.filters?.rvType[key as Drivables & Towables] === true) {
      classes.push(key)
    }
  }

  for (const key in params.filters?.listingCondition) {
    if (params.filters?.listingCondition[key as ListingConditions] === true) {
      listingConditions.push(key)
    }
  }

  return {
    MinPrice: params.filters?.rvPrice?.min,
    MaxPrice: params.filters?.rvPrice?.max,
    MinWeight: params.filters?.rvWeight?.min,
    MaxWeight: params.filters?.rvWeight?.max,
    MinYear: params.filters?.rvYear?.min,
    MaxYear: params.filters?.rvYear?.max,
    MinLength: params.filters?.rvLength?.min,
    MaxLength: params.filters?.rvLength?.max,
    MinSleepingCapacity: params?.filters?.sleepingCapacity?.min,
    MaxSleepingCapacity: params?.filters?.sleepingCapacity?.max,
    Make: params.filters?.rvBrand,
    Classes: classes,
    ListingConditions: listingConditions,
    OrderBy: params.sort
  } as RvSearchRequest
}

export const searchParametersToSearchQuery = (params: ISearchParameters) => {
  return {
    Types: getTypesFromFilterParams(params.filters ?? {}).sort(),

    RvBrand: params.filters?.rvBrand,

    MinLength: params.filters?.rvLength?.min,
    MaxLength: params.filters?.rvLength?.max,

    MinPrice: params.filters?.rvPrice?.min,
    MaxPrice: params.filters?.rvPrice?.max,

    MinRvYear: params.filters?.rvYear?.min,
    MaxRvYear: params.filters?.rvYear?.max,

    MinSleeps: params.filters?.sleepingCapacity?.min,
    MaxSleeps: params.filters?.sleepingCapacity?.max,

    MinWeight: params.filters?.rvWeight?.min,
    MaxWeight: params.filters?.rvWeight?.max,

    SearchAddress: params.location?.searchable ? params.location?.fullName : undefined,

    ListingConditions:
      Object.keys(params.filters?.listingCondition || []).length > 0 &&
      arrayUtils.getArrayFromRecord<ListingConditions>(params.filters?.listingCondition).length > 0
        ? arrayUtils.getArrayFromRecord<ListingConditions>(params.filters?.listingCondition)
        : undefined,

    SortOrder: params.sort !== 'Default' ? params.sort : undefined
  } as ISearchQuery
}

export const getTypesFromFilterParams = (filters: ISearchFilters) => {
  return arrayUtils.getArrayFromRecord<Drivables & Towables>(filters?.rvType)
}

export const queryStringToSearchFilters = (query: ISearchQuery): ISearchFilters => {
  return {
    rvType: arrayUtils.getArrayFromRecord<RvTypes>(
      String(query.Types)
        .split(',')
        .filter((v) => RvTypesGuard(v))
    ),
    rvPrice: {
      min: query.MinPrice !== undefined ? Number(query.MinPrice) : undefined,
      max: query.MaxPrice !== undefined ? Number(query.MaxPrice) : undefined
    },
    rvWeight: {
      min: query.MinWeight !== undefined ? Number(query.MinWeight) : undefined,
      max: query.MaxWeight !== undefined ? Number(query.MaxWeight) : undefined
    },
    rvLength: {
      min: query.MinLength !== undefined ? Number(query.MinLength) : undefined,
      max: query.MaxLength !== undefined ? Number(query.MaxLength) : undefined
    },
    rvYear: {
      min: query.MinRvYear !== undefined ? Number(query.MinRvYear) : undefined,
      max: query.MaxRvYear !== undefined ? Number(query.MaxRvYear) : undefined
    },
    sleepingCapacity: {
      min: query.MinSleeps !== undefined ? Number(query.MinSleeps) : undefined,
      max: query.MaxSleeps !== undefined ? Number(query.MaxSleeps) : undefined
    },
    rvBrand: query.RvBrand,
    listingCondition: query.ListingConditions
      ? arrayUtils.getRecordFromArray<ListingConditions>(
          String(query.ListingConditions).split(','),
          ListingConditionsList
        )
      : undefined
  }
}

export const queryStringToSortOrder = (query: ISearchQuery): RvSearchSorts => {
  return query.SortOrder ?? 'Default'
}

export const queryStringToLocation = async (query: ISearchQuery): Promise<IGooglePlace | undefined> => {
  if (!query.SearchAddress) return

  return await getGooglePlaceByName(query.SearchAddress)
}

export const geolocationToLocation = (geolocation?: IGeolocation): IGooglePlace | undefined => {
  if (geolocation?.latitude == null || geolocation?.longitude == null) return

  // only do a partial implementation
  return {
    geolocation: true,
    center: {
      lat: geolocation.latitude,
      lng: geolocation.longitude
    },
    city: {
      long_name: geolocation.city ?? '',
      short_name: geolocation.city ?? '',
      types: ['political', 'locality']
    },
    region: {
      long_name: geolocation.regionCode ?? '',
      short_name: geolocation.regionCode ?? '',
      types: ['political', 'administrative_area_level_1']
    },
    country: {
      long_name: geolocation.country ?? '',
      short_name: geolocation.country ?? '',
      types: ['political', 'country']
    },
    fullName: [geolocation?.city, geolocation?.regionCode, geolocation?.country].filter((x) => x).join(', '),
    mainText: geolocation?.city ?? '',
    types: ['political', 'locality'],
    geocode: {
      geometry: {
        // @ts-ignore
        location: {
          lat: () => geolocation.latitude as number,
          lng: () => geolocation.longitude as number,
          toJSON: () => ({
            lat: geolocation.latitude as number,
            lng: geolocation.longitude as number
          })
        }
      }
    }
  }
}
