export const useArrayUtils = () => {
  const getArrayFromRecord = <T extends string | number | symbol>(record?: Record<T, boolean>) => {
    return Object.keys(record || []).filter((x) => record?.[x as T])
  }

  const getRecordFromArray = <T extends string | number | symbol>(array: string[], typeEnum?: any) => {
    return array.reduce(
      (acc: Record<T, boolean>, v) => {
        if (typeEnum == null || Object.keys(typeEnum).includes(v)) acc[v] = true
        return acc
      },
      {} as Record<T, boolean>
    )
  }

  const distinct = <T extends string | number>(values?: T[]) => {
    if (values == null) return []
    return [...new Set(values)]
  }

  return {
    getArrayFromRecord,
    getRecordFromArray,
    distinct
  }
}
