import type { paths as MarketplaceRequests, components as MarketplaceTypes } from '~/types/api/marketplace-api-types'
import type { components as PlacesTypes } from '~/types/api/places-api-types'

export type Rv = MarketplaceTypes['schemas']['Rvezy.Marketplace.Api.Dtos.Response.RvResponse']
export type PlaceSeo = MarketplaceTypes['schemas']['Rvezy.Marketplace.Api.Dtos.Response.PlaceSeoResponse']
export type PlaceFaq = MarketplaceTypes['schemas']['Rvezy.Marketplace.Api.Dtos.Response.PlaceFaqResponse']

export type RvSearchRequest = NonNullable<MarketplaceRequests['/Rv/Search']['get']['parameters']['query']>

export type RvTypes = NonNullable<Rv['class']>
export const RvTypesList = [
  'Other',
  'OtherKnown',
  'ClassA',
  'ClassB',
  'ClassC',
  'TruckCamper',
  'FifthWheel',
  'TravelTrailer',
  'TentTrailer',
  'Hybrid',
  'ToyHauler',
  'MicroTrailer',
  'TinyHome',
  'ParkModel'
] as RvTypes[]
export const RvTypesGuard = (rvType: string) => RvTypesList.includes(rvType as RvTypes)

export type ListingConditions = NonNullable<Rv['listingCondition']>
export const ListingConditionsList = ['New', 'Used'] as ListingConditions[]
export const ListingConditionsGuard = (listingCondition: string) =>
  ListingConditionsList.includes(listingCondition as ListingConditions)

export type RvCategories = NonNullable<Rv['category']>
export const RvCategoriesList = ['Motorhome', 'Towable', 'Stationary', 'Other'] as RvCategories[]
export const RvCategoriesGuard = (rvCategory: string) => RvCategoriesList.includes(rvCategory as RvCategories)

export type LanguageCodes = NonNullable<
  MarketplaceTypes['schemas']['Rvezy.Marketplace.Api.Dtos.Request.CreateDealerRequest']['languageCode']
>
export type RvSearchSorts = NonNullable<RvSearchRequest['OrderBy']>

export type Place = PlacesTypes['schemas']['Rvezy.Places.Api.Dtos.Response.PlaceResponse']
export type PlaceAncestor = PlacesTypes['schemas']['Rvezy.Places.Api.Dtos.Response.PlaceAncestorResponse']

export type RegionCodes = NonNullable<Place['regionCode']>
export type CountryCodes = NonNullable<Place['countryCode']>
